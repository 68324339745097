import React, { SVGProps } from 'react';

export const Eyes = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00005 10.7C9.49122 10.7 10.7 9.49117 10.7 8C10.7 6.50883 9.49122 5.3 8.00005 5.3C6.50888 5.3 5.30005 6.50883 5.30005 8C5.30005 9.49117 6.50888 10.7 8.00005 10.7ZM7.10005 8C7.10005 7.50295 7.50299 7.1 8.00005 7.1C8.4971 7.1 8.90005 7.50295 8.90005 8C8.90005 8.49706 8.4971 8.9 8.00005 8.9C7.50299 8.9 7.10005 8.49706 7.10005 8Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8194 7.30653C16.0579 7.74301 16.0579 8.25699 15.8194 8.69347C14.9244 10.3317 12.3036 14.3047 7.93751 14.3047C3.54419 14.3047 1.01021 10.2819 0.168336 8.66305C-0.0499274 8.24334 -0.0499272 7.75666 0.168337 7.33695C1.01021 5.71808 3.54419 1.69531 7.93751 1.69531C12.3036 1.69531 14.9244 5.66829 15.8194 7.30653ZM11.9668 5.21619C12.9936 6.15322 13.7267 7.263 14.1453 8C13.7267 8.73699 12.9936 9.84678 11.9668 10.7838C10.8678 11.7867 9.53426 12.5047 7.93751 12.5047C6.33518 12.5047 5.02218 11.7814 3.95257 10.7781C2.95086 9.8385 2.24841 8.72786 1.85419 8C2.24841 7.27214 2.95086 6.1615 3.95257 5.22189C5.02218 4.21859 6.33518 3.49531 7.93751 3.49531C9.53426 3.49531 10.8678 4.21333 11.9668 5.21619Z"
        fill="currentColor"
      />
    </svg>
  );
};
