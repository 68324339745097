import React, { SVGProps, useId } from 'react';

export const MagicSquareLogo = (props: SVGProps<SVGSVGElement>) => {
  const gradientId = useId();
  return (
    <svg viewBox="0 0 20 20" {...props}>
      <g clip-path={`url(#${gradientId})`}>
        <path
          d="M4.57441 15.4256C3.77021 14.6214 3.77021 13.3175 4.57441 12.5133C5.37861 11.7091 6.68247 11.7091 7.48667 12.5133L11.4579 16.4846C12.2621 17.2888 12.2621 18.5927 11.4579 19.3969C10.6537 20.201 9.34987 20.201 8.54568 19.3969L4.57441 15.4256Z"
          fill="currentColor"
        />
        <path
          d="M8.54207 3.51541C7.73787 2.71121 7.73787 1.40735 8.54207 0.603149C9.34626 -0.20105 10.6501 -0.20105 11.4543 0.603149L15.4256 4.57441C16.2298 5.37861 16.2298 6.68247 15.4256 7.48667C14.6214 8.29087 13.3175 8.29087 12.5133 7.48667L8.54207 3.51541Z"
          fill="currentColor"
        />
        <path
          d="M0.603149 11.4543C-0.20105 10.6501 -0.20105 9.34626 0.603149 8.54207C1.40735 7.73787 2.71121 7.73787 3.51541 8.54207C4.31961 9.34626 4.31961 10.6501 3.51541 11.4543C2.71121 12.2585 1.40735 12.2585 0.603149 11.4543Z"
          fill="currentColor"
        />
        <path
          d="M16.4846 11.4579C15.6804 10.6537 15.6804 9.34987 16.4846 8.54567C17.2888 7.74148 18.5927 7.74148 19.3969 8.54567C20.2011 9.34987 20.2011 10.6537 19.3969 11.4579C18.5927 12.2621 17.2888 12.2621 16.4846 11.4579Z"
          fill="currentColor"
        />
        <path
          d="M4.57441 7.48306C3.77021 6.67886 3.77021 5.375 4.57441 4.5708C5.37861 3.7666 6.68247 3.7666 7.48667 4.5708L15.4292 12.5133C16.2334 13.3175 16.2334 14.6214 15.4292 15.4256C14.625 16.2298 13.3211 16.2298 12.5169 15.4256L4.57441 7.48306Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_686_82183">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
